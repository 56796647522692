import { apolloClient } from '@/store/graphql.config'
import { fetchGenerateTokenMutation } from '@/store/modules/auth/mutations/fetchGenerateToken.mutation'
import { IFetchTokenResponse } from '@/store/modules/auth/interfaces/fetchGenerateToken/IFetchTokenResponse'
import { IFetchTokenRequest } from '@/store/modules/auth/interfaces/fetchGenerateToken/IFetchTokenRequest'
import { ISetUserVerificationRequest } from '@/store/modules/auth/interfaces/setUserVerification/ISetUserVerificationRequest'
import { ISetFirstLogin } from '@/store/modules/auth/interfaces/setFirstLogin/ISetFirstLogin'
import { IFetchUserInfoResponse } from '@/store/modules/auth/interfaces/getUserInfo/IFetchUserInfoResponse'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { IRootState } from '@/store'
import { IAuthAction } from '@/store/modules/auth/IAuthAction'
import { getUserInformationAPI, setUserVerificationAPI, setFirstLogin } from '@/store/modules/auth/services/auth.api'
import { IAuthState } from '@/store/modules/auth/IAuthState'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'

const state = (): IAuthState => ({
  token: '',
  UID: '',
  UIDSig: '',
  UIDSignature: '',
  errorCode: 0,
  errorMessage: '',
  id_token: '',
  lang: 'en',
  status: '',
  statusMessage: '',
  user: {
    UID: '',
    UIDSig: '',
    UIDSignature: '',
    age: '',
    birthday: '',
    birthMonth: '',
    birthYear: '',
    city: '',
    country: '',
    email: '',
    firstName: '',
    firstLogin: false,
    lastName: '',
    username: '',
    gender: '',
    id_token: '',
    companyId: '',
    permissionSchema: [],
    allAvailablePermission: [],
    isConnected: false,
    isLoggedIn: false,
    isSiteUID: false,
    isSiteUser: false,
    isTempUser: false,
    locale: null,
    loginProvider: '',
    loginProviderUID: '',
    nickname: '',
    phone: '',
    photoURL: '',
    profileURL: '',
    providers: [],
    state: '',
    statusCode: 0,
    statusReason: '',
    userValidation: false,
    work: '',
    zip: ''
  },
  isUserVerified: false,
  isFullLoaded: false
})
const mutations: MutationTree<IAuthState> = {
  async [AuthMutationTypes.SET_GENERATE_TOKEN] (state, payload: string) {
    state.token = payload
  },
  async [AuthMutationTypes.SET_AUTH_USER] (state, payload: IAuthState) {
    if (!state.user.UID || state.user.UID !== payload.user.UID) {
      state.user = payload.user
      state.user.username = state.user.firstName + ' ' + state.user.lastName
      // console.info('SET_AUTH_USER', payload.user.email, payload.id_token)
      sessionStorage.setItem('id_token', payload.id_token)
    }
  },
  async [AuthMutationTypes.SET_USER_VERIFICATION] (state, payload: boolean) {
    state.isUserVerified = payload
  },
  async [AuthMutationTypes.SET_USER_INFO] (state, payload: IFetchUserInfoResponse) {
    state.user.companyId = payload.permissionSchema[0].companyId
    state.user.permissionSchema = payload.permissionSchema
    state.user.allAvailablePermission =
      payload.permissionSchema
        ?.flatMap((company) => company.rpDetails)
        ?.flatMap((rp) => rp.permission.split(','))
        ?.filter((value, index, self) => self.indexOf(value) === index) || []
    state.user.phone = payload.phone
    state.isFullLoaded = true
    state.user.firstLogin = payload.firstLogin
    state.user.userValidation = payload.userValidation
  }
}

const actions: ActionTree<IAuthState, IRootState> & IAuthAction = {
  async [AuthActionTypes.FETCH_GENERATE_TOKEN] ({ commit }, dto: IFetchTokenRequest) {
    const { data } = await apolloClient.mutate<IFetchTokenResponse, IFetchTokenRequest>({
      mutation: fetchGenerateTokenMutation,
      variables: {
        email: dto.email,
        password: dto.password
      }
    })
    localStorage.setItem('token', JSON.stringify(data?.generateCustomerToken.token))
    commit(AuthMutationTypes.SET_GENERATE_TOKEN, data?.generateCustomerToken.token || '')
  },
  async [AuthActionTypes.SET_USER_VERIFICATION] ({ commit }, dto: ISetUserVerificationRequest) {
    const data = await setUserVerificationAPI(dto)
    commit(AuthMutationTypes.SET_USER_VERIFICATION, data)
  },
  async [AuthActionTypes.SET_FIRST_LOGIN] ({ commit }, UID: ISetFirstLogin) {
    const data = await setFirstLogin(UID)
    commit(AuthMutationTypes.SET_FIRST_LOGIN, data)
  },
  async [AuthActionTypes.GET_USER_INFO] ({ commit, getters }, UID: string) {
    if (!getters.getUser.userValidation || getters.getUser.UID !== UID) {
      const data = await getUserInformationAPI(UID)
      commit(AuthMutationTypes.SET_USER_INFO, data)
    }
  }
}

const getters: GetterTree<IAuthState, IRootState> & {
  getIsFullLoaded(state: IAuthState): () => boolean
} = {
  getIsFullLoaded (state) {
    return function () {
      return state.isFullLoaded
    }
  },
  getUser (state: IAuthState) {
    return state.user
  }
}

const AuthModule = {
  state,
  mutations,
  actions,
  getters
}

export default AuthModule
