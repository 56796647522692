export const savedOrderList = `
    {
        "data": {
            "getSaveOrderForLater": [
                {
                    "id": "3261",
                    "date": "2022-11-22 06:45:13",
                    "total": "0.0000",
                    "deliver_to": "",
                    "bill_to": "Magento Pkwy Main Street,Austin,Texas,US,78758,",
                    "saved_order_later": true,
                    "is_active": false,
                    "cart_id": "Lhs4O8a1EV4dLd6xDnqIh2DjCQmGpbeq"
                },
                {
                    "id": "3262",
                    "date": "2022-11-23 10:04:25",
                    "total": "0.0000",
                    "deliver_to": "",
                    "bill_to": "Magento Pkwy Main Street,Austin,Texas,US,78758,Magento Pkwy Main Street,Austin,Texas,US,78758,",
                    "saved_order_later": true,
                    "is_active": false,
                    "cart_id": "DT1QTFNUvr1OrE9JzirDKb8HZrGs6EbH"
                }
            ]
        }
    }
`

export const shipmentPlaces = `[
    {
        "value": 1,
        "label": "Jeremiášova 1249/7, Praha 5"
    },
    {
        "value": 2,
        "label": "BAŽANTÍ 586/17, OSTRAVA - HRUŠOV"
    },
    {
        "value": 3,
        "label": "JAROMÍRA VEJVODY 1438, PRAHA 5 - ZBRASLAV"
    },
    {
        "value": 4,
        "label": "ŠIROKÁ 590/3, HAVÍŘOV - MĚSTO"
    },
    {
        "value": 5,
        "label": "ŠIROKÁ 590/3, HAVÍŘOV - MĚSTO"
    }
]`

export const orderTransportTypes = `[
    {
        "value": 0,
        "label": "Order type"
    },
    {
        "value": 1,
        "label": "Truck"
    },
    {
        "value": 2,
        "label": "Small"
    },
    {
        "value": 3,
        "label": "Collection of empty packaging - small order"
    },
    {
        "value": 4,
        "label": "Collection of empty packaging - truck"
    }
]`

export const orderStatus = `{
    "en":[
    {
        "value": "",
        "label": "Status"
    },
    {
        "value": "Completely Processed",
        "label": "Completely Processed"
    },
    {
        "value": "Fully Rejected",
        "label": "Fully Rejected"
    },
    {
        "value": "Not Relevant",
        "label": "Not Relevant"
    },
    {
        "value": "Not Yet Processed",
        "label": "Not Yet Processed"
    },
    {
        "value": "Partially Processed",
        "label": "Partially Processed"
    }
],
"cs":[
    {
        "value": "",
        "label": "Stav"
    },
    {
        "value": "Zcela zpracováno",
        "label": "Zcela zpracováno"
    },
    {
        "value": "Plně zamítnuto",
        "label": "Plně zamítnuto"
    },
    {
        "value": "Irelevantní",
        "label": "Irelevantní"
    },
    {
        "value": "Dosud nezpracováno",
        "label": "Dosud nezpracováno"
    },
    {
        "value": "Částečně zpracováno",
        "label": "Částečně zpracováno"
    }

],
"it":[
    {
        "value": "",
        "label": "Stato"
    },
    {
        "value": "Completely Processed",
        "label": "Completamente elaborato"
    },
    {
        "value": "Fully Rejected",
        "label": "Completamente rifiutato"
    },
    {
        "value": "Not Relevant",
        "label": "Non rilevante"
    },
    {
        "value": "Not Yet Processed",
        "label": "Non ancora elaborato"
    },
    {
        "value": "Partially Processed",
        "label": "Parzialmente elaborato"
    }

],
"sk":[
    {
        "value": "",
        "label": "Stav"
    },
    {
        "value": "Completely Processed",
        "label": "Kompletne spracované"
    },
    {
        "value": "Fully Rejected",
        "label": "Úplne zamietnuté"
    },
    {
        "value": "Not Relevant",
        "label": "Nie je relevantné"
    },
    {
        "value": "Not Yet Processed",
        "label": "Ešte nespracované"
    },
    {
        "value": "Partially Processed",
        "label": "Čiastočne spracované"
    }

]}`

export const orderStatusIT = `{
    "en":[
    {
        "value": "",
        "label": "Delivery Status"
    },
    {
        "value": "CANCELLED",
        "label": "CANCELLED"
    },
    {
        "value": "ORDER UNDER INVESTIGATION",
        "label": "ORDER UNDER INVESTIGATION"
    },
    {
        "value": "AWAITING SHIPMENT",
        "label": "AWAITING SHIPMENT"
    },
    {
        "value": "PARTIAL ORDER PREPARATION",
        "label": "PARTIAL ORDER PREPARATION"
    },
    {
        "value": "AWAITING FULLFILLMENT",
        "label": "AWAITING FULLFILLMENT"
    },
    {
        "value": "AWAITING DELIVERY",
        "label": "AWAITING DELIVERY"
    },
    {
        "value": "DELIVERED",
        "label": "DELIVERED"
    },
    {
        "value": "PARTIALLY DELIVERED",
        "label": "PARTIALLY DELIVERED"
    },
    {
        "value": "DELIVERY NOT AVAILABLE",
        "label": "DELIVERY NOT AVAILABLE"
    },
    {
        "value": "IN STOCK",
        "label": "IN STOCK"
    },
    {
        "value": "NOT DELIVERED",
        "label": "NOT DELIVERED"
    }
],
"it":[
    {
        "value": "",
        "label": "Stato consegna"
    },
    {
        "value": "CANCELLED",
        "label": "REVOCATO"
    },
    {
        "value": "ORDER UNDER INVESTIGATION",
        "label": "ORDINE IN CORSO DI VERIFICA"
    },
    {
        "value": "AWAITING SHIPMENT",
        "label": "DA EVADERE"
    },
    {
        "value": "PARTIAL ORDER PREPARATION",
        "label": "ORDINE PARZIALE IN PREPARAZIONE"
    },
    {
        "value": "AWAITING FULLFILLMENT",
        "label": "CONSEGNA IN PREPARAZIONE"
    },
    {
        "value": "AWAITING DELIVERY",
        "label": "IN CONSEGNA"
    },
    {
        "value": "DELIVERED",
        "label": "CONSEGNA EFFETTUATA"
    },
    {
        "value": "PARTIALLY DELIVERED",
        "label": "CONSEGNA PARZIALE"
    },
    {
        "value": "DELIVERY NOT AVAILABLE",
        "label": "ESITO CONSEGNA NON DISPONIBILE"
    },
    {
        "value": "IN STOCK",
        "label": "CONSEGNA IN GIACENZA"
    },
    {
        "value": "NOT DELIVERED",
        "label": "NON CONSEGNATO"
    }

]}`

export const companyList = `[
    {
        "value": "",
        "label": "Select Company"
    },
    {
        "value": "Brewery Roma",
        "label": "Brewery Roma"
    }
]`
