import gql from 'graphql-tag'

export const fetchPaymentMethodMutation = gql(`
mutation ($cart_id: String!, $payment_method: PaymentMethodInput!) {
  setPaymentMethodOnCart(
    input: {
      cart_id: $cart_id
      payment_method: $payment_method
    }
  ) {
    cart {
      selected_payment_method {
        code
        title
      }
    }
  }
}
`)
