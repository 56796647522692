
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import HelpActionButton from '@/components/HelpActionButton.vue'
import TutorialModal from '@/components/TutorialModal.vue'
import MessageModal from '@/components/common/MessageModal.vue'
import BannersComponent from '@/components/BannersComponent.vue'
import UserToolbar from '@/components/UserToolbar.vue'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { LinksActionType } from '@/store/modules/links/interfaces'
import { setI18nLanguage } from '@/i18n'
import Error401Page from './pages/Error401Page.vue'
import { constants } from '@/constants'
import BeforeLoginLayout from '@/components/layout/BeforeLoginLayout.vue'
import { LanguagesType } from '@/types'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import AppGlobalModal from '@/components/app/AppGlobalModal.vue'
import ModalComponent from './components/ModalComponent.vue'
import { ArticlesMutationType } from './store/modules/articles/interfaces/mutation-type'
import { FilesMutationType } from './store/modules/files/interfaces/mutation-type'
import ResizeObserver from '@/components/ResizeObserver.vue'
import { RolePermissionDetails } from './store/modules/users/interfaces/IUser'
import { IAuthState } from './store/modules/auth/IAuthState'
import { IPermissionsConfig } from './store/modules/main/intefaces/IConfig'
import { ISearchValues } from '@/store/modules/main/intefaces/search/IFetchSearchValuesResponse'
import { IPortalHardCodeLink } from './store/modules/links/interfaces/IPortalHardCodeLink'
import { TrainingLearningMutationType } from './store/modules/trainingLearning/interfaces/mutation-type'
import { SupportMutationType } from './store/modules/support/interfaces/mutation-type'
import { DocumentMutationType } from './store/modules/documents/interfaces/mutation-type'

export default defineComponent({
  name: 'App',

  components: {
    HelpActionButton,
    TutorialModal,
    Error401Page,
    MessageModal,
    BannersComponent,
    UserToolbar,
    BeforeLoginLayout,
    ModalComponent,
    AppGlobalModal,
    ResizeObserver
  },

  data: () => ({
    gigICode: '',
    gigIvToken: '',
    clientWidth: 0,
    isPortalChrome: true,
    theme: constants.application.theme,
    isLoaded: false,
    openModal: false,
    message: '',
    subMessage: '',
    searchValue: '',
    searchValues: [] as ISearchValues[],
    isErrorModal: true,
    copyRightMsg: `© Copyright ASAHI - ${new Date().getFullYear()}`,
    activeLanguage: (localStorage.getItem('lang') || 'en') as LanguagesType,
    dictionary: {
      en: {
        createAnOrder: 'CREATE AN ORDER',
        createAnOrderIt: 'Create An Order',
        cart: 'MY CART',
        legalClause: 'Legal clause',
        backUp: 'Back up',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Search',
        home: 'Homepage',
        profile: 'My Profile',
        contracts: 'Contracts',
        orders: 'Orders',
        history: 'History',
        savedOrders: 'Saved Orders Unsubmitted',
        catalog: 'Catalogue',
        products: 'Product Overview',
        bulkUpload: 'Order Bulk Upload',
        favorites: 'My Favourites',
        favoritesMenu: 'MY FAVOURITES',
        reporting: 'Reporting',
        myDocuments: 'My documents',
        marketingMaterials: 'Marketing Materials',
        newsEvents: 'News & Events',
        aboutAsahiMenu: 'About Asahi',
        support: 'Help & Support',
        faq: 'FAQ',
        knowledgeBase: 'Knowledge Base',
        supportTickets: 'Other Requests',
        eLearning: 'Academy',
        pubMenu: 'Menu Creator',
        emptiesManagement: 'Empties Management',
        contacts: 'Contacts',
        about: 'ABOUT US',
        privacyPolicy: 'Privacy Policy',
        privacyProtection: 'Privacy Protection',
        cookies: 'Cookies and personal data protection',
        brands: 'BRANDS',
        aboutAsahiFB: 'Asahi',
        productCode: 'Product code',
        removeItem: 'Remove Item',
        createNewUser: 'Create New User',
        trainingLearning: 'Training & Learning',
        Unit: 'Unit',
        actions: {
          edit: 'My Profile',
          logout: 'Logout'
        },
        languages: {
          es: 'Español',
          en: 'English'
        },
        login: 'Login',
        goToCart: 'GO TO CART',
        notificationsEmpty: 'No notifications',
        termsOfUse: 'Terms of use',
        corporateInformation: 'Corporate Information',
        corporateGovernance: 'Corporate Governance'
      },
      es: {
        createAnOrder: 'CREAR UN PEDIDO',
        administration: 'Administrace',
        cart: 'MI CARRITO',
        home: 'Página principal',
        profile: 'Mi perfil',
        contracts: 'Prehľad zmlúv',
        orders: 'Pedidos',
        history: 'Historia',
        savedOrders: 'Pedidos guardados no enviados',
        catalog: 'Catálogo',
        products: 'Descripción del producto',
        bulkUpload: 'Solicitar carga masiva',
        favorites: 'Mis favoritos',
        favoritesMenu: 'MI FAVORITO',
        backUp: 'Volver',
        reporting: 'Informes',
        aboutAsahiFB: 'Asahi',
        myDocuments: 'Mis documentos',
        marketingMaterials: 'Elementos de marketing',
        newsEvents: 'Noticias y Eventos',
        aboutAsahiMenu: 'Sobre Asahi',
        support: 'Servicio de asistencia',
        faq: 'Preguntas más frecuentes',
        knowledgeBase: 'Base de conocimientos',
        supportTickets: 'Otras solicitudes',
        eLearning: 'Akadémia Prvý pri zdroji',
        pubMenu: 'Prazdroj menu',
        legalClause: 'Právna doložka',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Buscar',
        contacts: 'Contacto',
        about: 'SOBRE NOSOTROS',
        privacyPolicy: 'Política de privacidad',
        privacyProtection: 'Protección de la privacidad',
        cookies: 'Cookies y Protección de Datos Personales',
        brands: 'MARCAS',
        productCode: 'kód produktu',
        createNewUser: 'Vytvoriť nového užívateľa',
        trainingLearning: 'Formación y aprendizaje',
        Unit: 'Barriles',
        actions: {
          edit: 'Mi perfil',
          logout: 'Cerrar sesión'
        },
        languages: {
          es: 'Español',
          en: 'English'
        },
        goToCart: 'ir al carrito',
        removeItem: 'eliminar producto',
        login: 'Acceso',
        notificationsEmpty: 'Žiadne notifikácie',
        termsOfUse: 'Condiciones de uso'
      }
    }
  }),

  computed: {
    menu () {
      return [
        {
          // label: 'Vytvořit objednávku',
          // href: '/catalog/products',
          name: 'xx',
          isActive: true,
          // icon: 'shopping-bag',
          children: [
            ...this.checkPermission('Home Page', {
              label: 'Hlavní stránka',
              href: '/',
              name: 'home',
              isActive: false,
              icon: 'home-alt'
            }),
            ...this.checkPermission('My Profile', {
              label: 'Můj profil',
              href: '/profile/details',
              name: 'profile',
              isActive: false,
              icon: 'user'
            }),
            ...(
              this.checkPermission('Catalogue', {
                label: 'Katalog',
                name: 'catalog',
                isActive: false,
                icon: 'beer',
                children: [
                  {
                    label: 'Produkty',
                    href: '/catalog/products',
                    name: 'products',
                    isActive: false
                  },
                  {
                    label: 'Moje oblíbené',
                    href: '/catalog/favorites',
                    name: 'favorites',
                    isActive: false
                  },
                  {
                    label: 'Solicitar carga masiva',
                    href: '/catalog/bulk-upload',
                    name: 'bulkUpload',
                    isActive: false
                  }
                ]
              })),

            ...(this.checkPermission('My Orders', {
              label: 'Pedidos',
              name: 'orders',
              isActive: false,
              icon: 'shopping-bag',
              children: [
                {
                  label: 'Historie objednávek',
                  href: '/orders/history',
                  name: 'history',
                  isActive: false
                },
                {
                  label: 'Pedidos guardados no enviados',
                  href: '/orders/saved',
                  name: 'savedOrders',
                  isActive: false
                }
              ]
            })),

            ...this.checkPermission('My Documents',
              {
                label: 'Moje dokumenty',
                href: '/my-documents',
                name: 'myDocuments',
                isActive: false,
                icon: 'newspaper'
              }),

            ...this.checkPermission('Reporting', {
              label: 'Přehled odběrů',
              href: '/reporting',
              name: 'reporting',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Contracts', {
              label: 'Přehled smluv',
              href: '/contracts',
              name: 'contracts',
              isActive: false,
              icon: 'file-contract'
            }),
            ...this.checkPermission('Support', {
              label: 'Podpora',
              href: '/support/faq',
              name: 'support',
              isActive: false,
              icon: 'question-circle',
              children: [
                {
                  label: 'Otázky a odpovědi',
                  href: '/support/faq',
                  name: 'faq',
                  isActive: false
                },
                {
                  label: 'O portálu',
                  href: '/support/knowledge-base',
                  name: 'knowledgeBase',
                  isActive: false
                },
                {
                  label: 'Kontaktujte nás',
                  href: '/support/tickets',
                  name: 'supportTickets',
                  isActive: false
                }
              ]
            }),
            ...this.checkPermission('Marketing Materials', {
              label: 'Materiály pro Vás',
              href: '/marketing-materials',
              name: 'marketingMaterials',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Training & Learning', {
              label: 'Formazione',
              name: 'trainingLearning',
              href: '/training',
              icon: 'book-open'
            }),
            ...this.checkPermission('News & Events', {
              label: 'Novinky a články',
              href: '/news',
              name: 'newsEvents',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('About Asahi', {
              label: 'A proposito di Peroni',
              href: this.getLinkByName('aboutAsahiMenu').url,
              name: 'aboutAsahiMenu',
              isActive: false,
              icon: 'university'
            }),
            ...this.checkPermission(
              'Empties Management',
              {
                label: 'Portale Materiale Circolante',
                href: constants.saml.empties,
                name: 'emptiesManagement',
                isActive: false,
                icon: 'external-link-alt'
              },
              this.isOnPremiseEmptiesUser || this.isOnPremiseFranchiseEmptiesUser
            ),
            ...this.checkPermission(
              'Pub Menu',
              {
                label: 'Prazdroj menu',
                href: constants.saml.pubMenuURL,
                name: 'pubMenu',
                isActive: false,
                icon: 'book-open'
              },
              this.isPOSUser || this.isOutletUser
            )
          ]
        },
        ...this.checkPermission('Catalogue', {
          label: 'Vytvořit objednávku',
          href: '/catalog/products',
          name: 'createAnOrder',
          isActive: true,
          icon: 'shopping-bag'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'MI CARRITO',
          name: 'cart',
          ...(this.clientWidth >= 1000
            ? {
                href: '',
                precart: true
              }
            : {
                href: '/cart'
              }),
          icon: 'shopping-cart'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'Moje oblíbené',
          name: 'favoritesMenu',
          href: '/catalog/favorites',
          icon: 'heart'
        })
      ]
    },
    footerLinksBrands () {
      const footerBrandLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'Footer Brand')
        .map((data) => ({
          label: data.name,
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [
        { title: 'brands' },
        ...footerBrandLinks
      ]
    },
    footerLinksSocial () {
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'Social Media')
        .map((data) => ({
          label: data.name, // Assuming you have a function to get the label from the frontend based on the name
          link: data.url, // Assuming 'url' holds the URL fetched from the backend
          target: '_blank'
        }))

      return aboutUsLinks
    },
    footerLinks () {
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'About Us')
        .map((data) => ({
          label: data.name, // Use the 'label' field from the backend as the label for about us links
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [
        {
          title: 'about'
        },
        ...aboutUsLinks
      ]
    },
    footerLinksMobile () {
      const mobileBrandsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'Mobile Brands')
        .map((data) => ({
          label: data.label, // Use the 'label' field from the backend as the label for mobile brands
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'About Us')
        .map((data) => ({
          label: data.name, // Use the 'label' field from the backend as the label for about us links
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [...mobileBrandsLinks, ...aboutUsLinks]
    },
    ...mapState('main', ['pageNotFound']),
    ...mapState('basket', ['id', 'items', 'progressBarMsg']),
    ...mapState('catalog', ['wishlist']),
    ...mapGetters('basket', ['progressBarMessage']),
    ...mapState('main', ['permissions']),

    linksStore () {
      return this.$store.state.links
    },
    queries () {
      return this.$store.state.catalog.queries
    },
    showSearchBar () {
      return this.checkPermission('Catalogue', {}).length !== 0
    },

    languages () {
      return ['es', 'en']
    },

    menuLinks () {
      const menu = [...this.menu]

      const menuWithoutOtherLinks = []
      if (menu[0]?.children?.length > 0) {
        for (let i = 0; i < menu[0]?.children?.length; i++) {
          const element = menu[0].children[i]
          if ('isOther' in element === false) {
            menuWithoutOtherLinks.push(element)
          }
        }
      }

      return menu
    },

    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    total () {
      return this.$store.state.links.portalHardCodeLink.total
    },

    limit () {
      return this.$store.state.links.portalHardCodeLink.limit
    },

    computedDictionary () {
      const links = this.linksStore?.links?.reduce((acc, value, index) => {
        acc['custom-link' + (index + 1)] = value.title
        return acc
      }, {} as Record<string, string>)

      const activeLanguage = this.activeLanguage as keyof typeof this.dictionary
      return { ...this.dictionary, [activeLanguage]: { ...this.dictionary[activeLanguage], ...links } }
    },

    user () {
      return this.$store.state.auth.user
    },
    isLoggedIn () {
      return this.user.isLoggedIn // && !!this.user.userValidation
    },
    notAuthorized () {
      return this.$route.meta?.requireAuthentication && !this.isLoggedIn
    },
    showFooter (): boolean {
      return !this.$route.meta?.hideFooter
    },
    cartItems () {
      return this.items?.length
    },
    favoritesItems () {
      return this.wishlist?.items_v2?.items?.length
    },
    isPOSUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails.some((rp) => rp.customerType === 'POS'))
    },
    isWHSLUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails?.some((rp) => rp.customerType === 'WHSL'))
    },
    isOutletUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.customerType === 'Outlets')
      )
    },
    isOnPremiseEmptiesUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.rolename === 'On Premise Empties Management')
      )
    },
    isOnPremiseFranchiseEmptiesUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.rolename === 'On Premise Franchisee Empties Management')
      )
    },
    allPermission () {
      return this.user.allAvailablePermission
    },
    helpPagesStore () {
      return this.$store.state.helpPages
    },
    customerType (): any {
      if (this.user && this.user?.permissionSchema?.length > 0) {
        return this.user?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return []
    },
    userMenuPermission (): IPermissionsConfig {
      return this.permissions || []
    },
    showProfiles (): boolean {
      return this.getValueByLabel(this.userMenuPermission, 'My Profile') && this.allPermission?.includes('My Profile')
    }

  },

  watch: {
    isLoggedIn: {
      handler (value) {
        if (value === true) {
          this.fetchLinks()
          this.fetchPortalHardCodeLink()
        }
      }
    },
    activeLanguage: {
      handler (value, oldValue) {
        if (oldValue ? value !== oldValue : !!value) {
          this.setMagentoStore(value)
          if (this.isLoggedIn) {
            this.fetchLinks()
            this.fetchPortalHardCodeLink()
          }
        }
      },
      immediate: true
    },
    'user.country': {
      handler () {
        this.setMagentoStore(this.activeLanguage)
      },
      immediate: true
    },
    $route () {
      this.isPortalChrome = !this.$route.meta?.noPortalChrome
      const urlParams = new URLSearchParams(window.location.search)
      this.gigICode = urlParams.get('gig_i_code') || ''
      this.gigIvToken = urlParams.get('gig_i_vToken') || ''
    },
    progressBarMsg (value, oldValue) {
      if ((oldValue !== value)) {
        return value
      }
    }
  },

  created () {
    this.$store.dispatch('main/FETCH_PERMISSIONS', { root: true })
    this.isPortalChrome = !this.$route.meta?.noPortalChrome
    const urlParams = new URLSearchParams(window.location.search)
    this.gigICode = urlParams.get('gig_i_code') || ''
    this.gigIvToken = urlParams.get('gig_i_vToken') || ''
  },

  async mounted () {
    this.$nextTick(function () {
      window.gigya.hasSession().then(this.checkSession)
      window.gigya.socialize.addEventHandlers({
        onLogin: async (res: IAuthState) => {
          this.updateUserInfo(res)
          await this.$store.dispatch('main/FETCH_PERMISSIONS', { root: true })
          localStorage.setItem('shipToId', '')
          await this.$store.dispatch('basket/CREATE_EMPTY_CART', { root: true })
        }
      })
    })
  },

  methods: {
    // menu check
    getValueByLabel (arrayData: IPermissionsConfig | string, searchString: string) {
      // Parse the JSON array if it hasn't been parsed already
      if (typeof arrayData === 'string') {
        arrayData = JSON.parse(arrayData)
      }
      if (searchString === 'Home Page') {
        return true
      }

      if (Array.isArray(arrayData) && arrayData.length) {
        let result: string | false = false
        arrayData.forEach((item) => {
          if (item.label === searchString && item.value === '1') {
            result = item.value
            // Early return if a match is found
          }
        })
        return result
      }
      return false
    },
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) {
        localStorage.setItem('customertype', this.customerType?.customerType)
      }
      if (this.allPermission?.includes(permission) &&
          additionalPremmision &&
          this.getValueByLabel(this.userMenuPermission, permission)) {
        return [value]
      } else {
        return []
      }
    },
    async dispatchHelpPages () {
      await this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },
    openTutorialModal () {
      if (
        !(this.helpPagesStore !== undefined && this.helpPagesStore.helpPages && this.helpPagesStore.helpPages.length)
      ) {
        this.dispatchHelpPages()
      }
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).open()
    },
    closeTutorialModal () {
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).close()
    },
    menuNavigate (item: CustomEvent) {
      if (
        item.detail?.name === 'eLearning' ||
        item.detail?.name === 'pubMenu' ||
        (item.detail?.name === 'emptiesManagement')
      ) {
        this.$gtm.trackEvent({
          event: item.detail?.name + '_click'
        })
        window.gigya.fidm.saml.initSSO({
          spName: item.detail?.name,
          redirectURL: item.detail.href
        })
        return
      } else if (/^https?:\/\//i.test(item.detail.href) || /^https?:\/\//i.test(item.detail)) {
        const link = item.detail.href ? item.detail.href : item.detail
        window.open(link, '_blank')
        return
      }

      this.$navigate(item)
    },

    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    },

    fetchLinks () {
      this.$store.dispatch(LinksActionType.FETCH_ALL_LINKS)
    },

    fetchPortalHardCodeLink () {
      this.$store.dispatch(LinksActionType.FETCH_PORTAL_HARD_CODE_LINK)
    },
    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    },

    /**
     * Method to update logged in user info into vuex store
     * @param res
     */
    async updateUserInfo (res: IAuthState) {
      sessionStorage.setItem('uid', res.UID) // This is for Google Analytics
      this.$store.commit('SET_AUTH_USER', res)
      await this.$store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID).then(() => {
        if (localStorage.getItem('lang') !== null) {
          this.activeLanguage = (localStorage.getItem('lang') ||
            'en') as LanguagesType
        }
        if (!this.user.userValidation && !this.$route.meta?.isUserVerification) {
          this.userLogout()
        }
      })
      if (!!this.user.userValidation && !this.$route.meta?.isUserVerification) {
        await this.checkPOSAcademyUser()
      }
      if (this.user.firstLogin === true && this.user.userValidation) {
        this.openTutorialModal()
      }
    },
    checkPOSAcademyUser () {
      const permission = this.user.permissionSchema
      if (permission) {
        permission.forEach((item) => {
          const rolePOSAcademy = item.rpDetails.find(
            (roleItem) =>
              roleItem.rolename === 'POS Academy' || roleItem.roleid === 'f5ab4859-9197-47e5-bc9e-097283c35d94'
          )
          if (rolePOSAcademy) {
            window.gigya.fidm.saml.initSSO({
              spName: 'eLearning',
              redirectURL: constants.saml.eLearningURL
            })
          }
        })
      }
    },
    /**
     * Method to initiate logout action
     * on click action of logout button
     */
    userLogout () {
      localStorage.clear()
      sessionStorage.removeItem('uid')
      const params = {
        UID: this.user.UID,
        callback: this.handleLogout
      }
      window.gigya.accounts.logout(params)
    },
    /**
     * Method to perform action after user logs out
     * Navigate user to home page
     */
    handleLogout () {
      this.$store.dispatch('logout')
      this.$navigate('/login')
    },
    /**
     * Method to check user session if it exist
     * if it does not exist then redirect user to login page
     * @param sessionExist
     */
    async checkSession (sessionExist: boolean) {
      if (sessionExist) {
        window.gigya.socialize.getUserInfo({ callback: this.updateUserInfo })
      } else {
        if (!this.$route.meta?.isUserVerification && this.$route.meta?.requireAuthentication) {
          this.$navigate('/login')
        }
      }
      this.isLoaded = true
    },
    /**
     * Callback method on click of Profile link under top right user name
     * @param event
     */
    userEdit () {
      this.$navigate('/profile/details')
    },

    setAgeConfirmed (isAgeConfirmed: boolean) {
      if (this.theme === 'emea') {
        this.isPortalChrome = isAgeConfirmed
      }
    },
    setMagentoStore (language: string) {
      localStorage.setItem('store', `LivePortal_EMEA_${language}`)
    },

    async search (event: CustomEvent) {
      this.searchValue = event.detail

      await this.fetchSearching(this.searchValue)
      const catalogQuery = {
        ...this.queries,
        search: this.searchValue
      }
      this.$store.commit('catalog/SET_QUERIES', catalogQuery, { root: true })
      this.$store.commit(ArticlesMutationType.SET_SEARCH, this.searchValue, { root: true })
      this.$store.commit(FilesMutationType.SET_SEARCH, this.searchValue, { root: true })
      this.$store.commit(TrainingLearningMutationType.SET_SEARCH_TRAINING, this.searchValue, { root: true })
      this.$store.commit(SupportMutationType.SET_SEARCH, this.searchValue, { root: true })
      this.$store.commit(DocumentMutationType.SET_FILTERS, { search: this.searchValue }, { root: true })
    },

    async fetchSearching (dto: string): Promise<ISearchValues[] | undefined> {
      try {
        if (dto != null && dto !== '') {
          const result = [] as ISearchValues[]

          if (this.getValueByLabel(this.userMenuPermission, 'Catalogue')) {
            result.push({ name: this.$t('Catalogue'), path: '/catalog/products' })
          }
          if (this.getValueByLabel(this.userMenuPermission, 'News & Events')) {
            result.push({ name: this.$t('News & Events'), path: '/news' })
          }
          if (this.getValueByLabel(this.userMenuPermission, 'Marketing Materials')) {
            result.push({ name: this.$t('Marketing Materials'), path: '/marketing-materials' })
          }
          if (this.getValueByLabel(this.userMenuPermission, 'Training & Learning')) {
            result.push({ name: this.$t('Training & learning'), path: '/training' })
          }
          if (this.getValueByLabel(this.userMenuPermission, 'Support')) {
            result.push({ name: this.$t('Help & Support'), path: '/support/faq' })
          }
          if (this.getValueByLabel(this.userMenuPermission, 'Support')) {
            result.push({ name: this.$t('Knowledge Base'), path: '/support/knowledge-base' })
          }
          if (this.getValueByLabel(this.userMenuPermission, 'My Documents')) {
            result.push({ name: this.$t('My Documents'), path: '/my-documents' })
          }
          this.searchValues = result
        }
        return this.searchValues
      } catch (error) {
        console.error(error)
      }
    },
    async removeProductFromCart (productId: string) {
      if (this.$store.state.basket?.id && productId) {
        await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.$store.state.basket.id, cart_item_id: productId }, { root: true })
        await this.$store.dispatch('basket/FETCH_MIN_CART_QUERY_FOR_CATALOG', { cart_id: this.$store.state.basket.id }, { root: true })
      }
    },
    onResizePortal ({ height }: { height: number }) {
      this.clientWidth = document.querySelector('body')?.clientWidth || height
    }
  }
})
