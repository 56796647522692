import { AxiosResponse } from 'axios'
import { generalServiceAPI } from '@/store/api-instance'
import { IDocumentResponse, IHistoryDocumentResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { ITesiDocumentsRequest } from '../interfaces/api/ITesiDocuments/ITesiDocumentsRequest'
import { ITesiDocumentResponse } from '../interfaces/api/ITesiDocuments/ITesiDocumentsResponse'
import { ICablogDocumentsRequest } from '../interfaces/api/ICablogDocuments/ICablogDocumentsRequest'
import { ICablogDocumentResponse } from '../interfaces/api/ICablogDocuments/ICablogDocumentsResponse'

export const documentsAPI = {
  async getDocuments (dto: IDocumentRequest): Promise<IDocumentResponse> {
    const response: AxiosResponse<IDocumentResponse> = await generalServiceAPI.get('mydocuments/GetAllDocuments', { params: dto })
    return response.data
  },
  async getTesiDocuments (dto: ITesiDocumentsRequest): Promise<ITesiDocumentResponse> {
    const response: AxiosResponse<ITesiDocumentResponse> = await generalServiceAPI.get('documents/GetTesiDetails', {
      params: dto
    })
    return response.data
  },
  async getCablogDocuments (dto: ICablogDocumentsRequest): Promise<ICablogDocumentResponse> {
    const response: AxiosResponse<ICablogDocumentResponse> = await generalServiceAPI.get('documents/GetCablogDetails', {
      params: dto
    })
    return response.data
  },
  async getInvoiceDocuments (dto: IDocumentRequest): Promise<IHistoryDocumentResponse> {
    const response: AxiosResponse<IHistoryDocumentResponse> = await generalServiceAPI.get(`mydocuments/GetInvoiceDocumentsByOrderId/${dto.id}`, { params: dto })
    return response.data
  },
  async getCreditDocuments (dto: IDocumentRequest): Promise<IHistoryDocumentResponse> {
    const response: AxiosResponse<IHistoryDocumentResponse> = await generalServiceAPI.get(`mydocuments/GetCreditNotesDocumentsByOrderId/${dto.id}`, { params: dto })
    return response.data
  },
  async getShippingDocuments (dto: IDocumentRequest): Promise<IHistoryDocumentResponse> {
    const response: AxiosResponse<IHistoryDocumentResponse> = await generalServiceAPI.get(`mydocuments/GetAllDocumentsByOrderId/${dto.id}`, { params: dto })
    return response.data
  },
  async getOtherDocuments (dto: IDocumentRequest): Promise<IHistoryDocumentResponse> {
    const response: AxiosResponse<IHistoryDocumentResponse> = await generalServiceAPI.get(`mydocuments/GetOtherDocumentsByOrderId/${dto.id}`, { params: dto })
    return response.data
  },
  async getProformasDocuments (dto: IDocumentRequest): Promise<IHistoryDocumentResponse> {
    const response: AxiosResponse<IHistoryDocumentResponse> = await generalServiceAPI.get(`mydocuments/GetProformaInvoiceDocumentsByOrderId/${dto.id}`, { params: dto })
    return response.data
  }
}
