import gql from 'graphql-tag'

export const fetchTransportTypeMutation = gql(`
mutation ($cart_id: String!, $transporttype: String!, $currency: String!) {
  setTransportType(
      cart_id: $cart_id
      transporttype: $transporttype
      currency: $currency
  ) {
    message
  }
}
`)
