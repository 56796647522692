import { ActionTree, MutationTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { magnoliaServiceAPI } from '@/store/api-instance'
import { IRootState } from '@/store'
import {
  ILinksState,
  IFetchLinksRequest,
  IFetchLinksResponse,
  LinksMutationType,
  ILinksMutations,
  LinksActionType,
  ILinksActions,
  ILinks,
  IFetchPortalHardCodeLinkRequest,
  IFetchPortalHardCodeLinkResponse,
  IPortalHardCodeLinkActions,
  IPortalHardCodeLinkMutations,
  IPortalHardCodeLinkState
} from './interfaces'

const linksState = (): ILinksState => ({
  links: [],
  loading: false
})

const portalState = (): IPortalHardCodeLinkState => ({
  portalHardCodeLink: {
    total: 0,
    offset: 0,
    limit: 0,
    results: []
  },
  search: '',
  loading: false
})

const fetchLinks = async (dto: IFetchLinksRequest): Promise<IFetchLinksResponse> => {
  const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchLinksResponse>('links', { params: dto })
  return response.data
}

const portalHardCodeLinkAPI = async (
  dto: IFetchPortalHardCodeLinkRequest
): Promise<IFetchPortalHardCodeLinkResponse> => {
  const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchPortalHardCodeLinkResponse>('portalExtLinks', {
    params: dto
  })
  return response.data
}

const fetchAllLinks = async (dto: IFetchLinksRequest, oldData?: Array<ILinks>): Promise<Array<ILinks>> => {
  const { results, total, limit } = await fetchLinks(dto)

  oldData = (oldData || []).concat(results)

  if (total > oldData.length) {
    return await fetchAllLinks({ ...dto, offset: oldData.length, limit }, oldData)
  }

  return oldData
}

const mutations: MutationTree<ILinksState & IPortalHardCodeLinkState> & ILinksMutations & IPortalHardCodeLinkMutations =
  {
    [LinksMutationType.SET_LOADING] (state: ILinksState & IPortalHardCodeLinkState, payload: boolean) {
      state.loading = payload
    },
    [LinksMutationType.SET_ALL_LINKS] (state: ILinksState & IPortalHardCodeLinkState, payload: Array<ILinks>) {
      state.links = payload
    },
    [LinksMutationType.SET_PORTAL_HARD_CODE_LINK] (
      state: ILinksState & IPortalHardCodeLinkState,
      payload: IFetchPortalHardCodeLinkResponse
    ) {
      state.portalHardCodeLink = payload
    }
  }

const actions: ActionTree<ILinksState & IPortalHardCodeLinkState, IRootState> &
  ILinksActions &
  IPortalHardCodeLinkActions = {
    async [LinksActionType.FETCH_ALL_LINKS] ({ commit }, dto: IFetchLinksRequest) {
      commit(LinksMutationType.SET_LOADING, true)
      const data = await fetchAllLinks(dto)
      commit(LinksMutationType.SET_ALL_LINKS, data)
      commit(LinksMutationType.SET_LOADING, false)
    },
    async [LinksActionType.FETCH_PORTAL_HARD_CODE_LINK] ({ commit }, dto: IFetchPortalHardCodeLinkRequest) {
      commit(LinksMutationType.SET_LOADING, true)
      const data = await portalHardCodeLinkAPI(dto)
      commit(LinksMutationType.SET_PORTAL_HARD_CODE_LINK, data)
      commit(LinksMutationType.SET_LOADING, false)
    }
  }

export default {
  linksState,
  portalState,
  mutations,
  actions
}
