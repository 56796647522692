import gql from 'graphql-tag'

export const fetchMinCartForCatalogQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  ) {
    note_for_driver
    customer_order_number
    delivery_date
    delivery_method
    progress_bar_data {
      isFullTruck
      iMinimumOrderPallet,
      iMaximumOrderPallet,
      iMinimumOrderWeight,
      iMaximumOrderWeight,
      iTotalCartWeight,
      iTotalCartPalletQty,
      iTotalQty,
      handstacked,
      canProceed
    }
    id
    ship_to_id
    grand_total_your_price
    items {
      id
      product {
        id
        name
        sku
        image {
          url
        }
        __typename
      }
      quantity
      quantity_total
    }
  }
}
`)
