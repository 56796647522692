import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    locale () {
      return this.$i18n.locale as string
    }
  },

  watch: {
    locale (value, oldValue) {
      if (value !== oldValue) (this as any).refresh()
    }
  }
})
