import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { ISupportState } from '@/store/modules/support/interfaces/ISupportState'
import { ISupportMutations } from '@/store/modules/support/interfaces/ISupportMutations'
import { SupportMutationType } from '@/store/modules/support/interfaces/mutation-type'
import { ISupportActions } from '@/store/modules/support/interfaces/ISupportActions'
import { SupportActionType } from '@/store/modules/support/interfaces/action-type'
import { supportAPI } from '@/store/modules/support/api/support-api'
import { ISupportRequest } from '@/store/modules/support/interfaces/api/ISupportRequest'
import { ISupportItem, ISupportPage, IKnowledgeBaseItem } from '@/store/modules/support/interfaces/api/ISupportResponse'

const state = (): ISupportState => ({
  faqItems: [],
  faqPages: [],
  faqKnowledgeBase: [],
  loading: false,
  search: ''
})

const mutations: MutationTree<ISupportState> & ISupportMutations = {
  [SupportMutationType.SET_LOADING] (state: ISupportState, payload: boolean) {
    state.loading = payload
  },
  async [SupportMutationType.SET_FAQ_ITEMS] (state: ISupportState, payload: Array<ISupportItem>) {
    state.faqItems = payload
  },
  async [SupportMutationType.SET_FAQ_PAGES] (state: ISupportState, payload: Array<ISupportPage>) {
    state.faqPages = payload
  },
  async [SupportMutationType.SET_KNOWLEDGE_BASE_ITEMS] (state: ISupportState, payload: Array<IKnowledgeBaseItem>) {
    state.faqKnowledgeBase = payload
  },
  [SupportMutationType.SET_SEARCH] (state: ISupportState, payload: string) {
    state.search = payload
  }
}

const actions: ActionTree<ISupportState, IRootState> & ISupportActions = {
  async [SupportActionType.FETCH_FAQ_ITEMS] ({ commit }, dto: ISupportRequest) {
    commit(SupportMutationType.SET_LOADING, true)
    const response = await supportAPI.getFaqItems(dto)
    commit(SupportMutationType.SET_FAQ_ITEMS, response.results)
    commit(SupportMutationType.SET_LOADING, false)
  },
  async [SupportActionType.FETCH_ALL_FAQ_ITEMS] ({ commit }, dto: ISupportRequest) {
    commit(SupportMutationType.SET_LOADING, true)
    const results = await supportAPI.getAllFaqItems(dto)
    commit(SupportMutationType.SET_FAQ_ITEMS, results)
    commit(SupportMutationType.SET_LOADING, false)
  },
  async [SupportActionType.FETCH_FAQ_PAGES] ({ commit }, dto: ISupportRequest) {
    commit(SupportMutationType.SET_LOADING, true)
    const response = await supportAPI.getFaqPages(dto)
    commit(SupportMutationType.SET_FAQ_PAGES, response.results)
    commit(SupportMutationType.SET_LOADING, false)
  },
  async [SupportActionType.FETCH_KNOWLEDGE_BASE_ITEMS] ({ commit }, dto: ISupportRequest) {
    commit(SupportMutationType.SET_LOADING, true)
    const response = await supportAPI.getFaqKnowledgeBaseItems(dto)
    commit(SupportMutationType.SET_KNOWLEDGE_BASE_ITEMS, response.results)
    commit(SupportMutationType.SET_LOADING, false)
  },
  async [SupportActionType.FETCH_ALL_KNOWLEDGE_BASE_ITEMS] ({ commit }, dto: ISupportRequest) {
    commit(SupportMutationType.SET_LOADING, true)
    const results = await supportAPI.getAllFaqKnowledgeBaseItems(dto)
    commit(SupportMutationType.SET_KNOWLEDGE_BASE_ITEMS, results)
    commit(SupportMutationType.SET_LOADING, false)
  }
}

export default {
  state,
  mutations,
  actions
}
