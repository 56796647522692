
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import { AxiosResponse } from 'axios'
import { magnoliaServiceAPI } from '@/store/api-instance'
import refreshAfterChangeLanguage from '@/mixins/refreshAfterChangeLanguage'

import 'vue3-carousel/dist/carousel.css'

interface IBannersContent {
  id: string
  locationId: string
  image: {
    link?: string
    [key: string]: any
  }
  [key: string]: any
}

interface IBannersResponse {
  limit: number
  offset: number
  total: number
  results: Array<IBannersContent>
}
export default defineComponent({
  name: 'BannersComponent',

  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },

  mixins: [refreshAfterChangeLanguage],

  data: () => {
    return {
      slides: null as Array<IBannersContent> | null
    }
  },

  computed: {
    ...mapState('main', ['bannersConfig']),

    isLoggedIn () {
      return this.$store.state.auth.user.isLoggedIn
    },

    path () {
      const path = this.$route.name as string
      if (path === 'homepage' && this.isLoggedIn) return path + 'AfterLogin'
      return path
    }
  },

  watch: {
    path () {
      this.refresh()
    },
    isLoggedIn () {
      this.refresh()
    }
  },

  created () {
    this.refresh()
  },

  methods: {
    refresh () {
      this.getStaticData()
    },

    async getStaticData (oldData?: Array<IBannersContent>, { offset = 0, limit = 10 } = {}) {
      await this.$store.dispatch('main/FETCH_BANNERS_CONFIG', { root: true })

      if (!this.path) return
      try {
        const apiPath = '/banners'

        const response: AxiosResponse<IBannersResponse> = await magnoliaServiceAPI.get(apiPath, {
          params: { offset, limit, locationId: this.path }
        })

        const data = (oldData || []).concat(response.data.results)

        this.slides = data.filter(image => image.link !== null)

        if (response.data.total > this.slides.length) {
          this.getStaticData(data, { offset: data.length, limit })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
})
