import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { DocumentActionType } from '@/store/modules/documents/interfaces/action-type'
import { DocumentMutationType } from '@/store/modules/documents/interfaces/mutation-type'
import { IDocumentsState } from '@/store/modules/documents/interfaces/IDocumenstState'
import { IDocumentsMutations } from '@/store/modules/documents/interfaces/IDocumentsMutations'
import { IDocument, IHistoryDocumentResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { documentsAPI } from '@/store/modules/documents/api/documents-api'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { IDocumentsActions } from '@/store/modules/documents/interfaces/IDocumentsActions'
import { ITesiDocument } from './interfaces/api/ITesiDocuments/ITesiDocumentsResponse'
import { ICablogDocument } from './interfaces/api/ICablogDocuments/ICablogDocumentsResponse'
import { ITesiDocumentsRequest } from './interfaces/api/ITesiDocuments/ITesiDocumentsRequest'
import { ICablogDocumentsRequest } from './interfaces/api/ICablogDocuments/ICablogDocumentsRequest'
import { IFilter } from '../orders/interfaces/IFilter'

const state = (): IDocumentsState => ({
  documents: [],
  tesiDocuments: [],
  cablogDocuments: [],
  invoiceDocuments: [],
  creditDocuments: [],
  shippingDocuments: [],
  otherDocuments: [],
  loading: false,
  errors: null,
  filters: {} as IFilter,
  proformasDocuments: []
})

const mutations: MutationTree<IDocumentsState> & IDocumentsMutations = {
  [DocumentMutationType.SET_LOADING] (state: IDocumentsState, payload: boolean) {
    state.loading = payload
  },
  [DocumentMutationType.SET_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.documents = payload
  },
  [DocumentMutationType.SET_TESI_DOCUMENTS] (state: IDocumentsState, payload: ITesiDocument[]) {
    state.tesiDocuments = payload
  },
  [DocumentMutationType.SET_CABLOG_DOCUMENTS] (state: IDocumentsState, payload: ICablogDocument[]) {
    state.cablogDocuments = payload
  },
  [DocumentMutationType.SET_SHIPPING_DOCUMENTS] (state: IDocumentsState, payload: IHistoryDocumentResponse[]) {
    state.shippingDocuments = payload
  },
  [DocumentMutationType.SET_OTHER_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.otherDocuments = payload
  },
  [DocumentMutationType.SET_INVOICE_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.invoiceDocuments = payload
  },
  [DocumentMutationType.SET_CREDIT_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.creditDocuments = payload
  },
  [DocumentMutationType.SET_ERROR] (state: IDocumentsState, payload: any) {
    state.errors = payload
  },
  [DocumentMutationType.SET_FILTERS] (state: IDocumentsState, payload: IFilter) {
    state.filters = payload
  },
  [DocumentMutationType.SET_PROFORMAS_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.proformasDocuments = payload
  }
}

const actions: ActionTree<IDocumentsState, IRootState> & IDocumentsActions = {
  async [DocumentActionType.FETCH_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getDocuments(dto)
      commit(DocumentMutationType.SET_DOCUMENTS, response.documentList)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },
  async [DocumentActionType.FETCH_TESI_DOCUMENTS] ({ commit }, dto: ITesiDocumentsRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getTesiDocuments(dto)
      commit(DocumentMutationType.SET_TESI_DOCUMENTS, response.documentList)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_TESI_DOCUMENTS, [])
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },
  async [DocumentActionType.FETCH_CABLOG_DOCUMENTS] ({ commit }, dto: ICablogDocumentsRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getCablogDocuments(dto)
      commit(DocumentMutationType.SET_CABLOG_DOCUMENTS, response.documentList)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_CABLOG_DOCUMENTS, [])
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },

  async [DocumentActionType.FETCH_SHIPPING_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getShippingDocuments(dto)
      commit(DocumentMutationType.SET_SHIPPING_DOCUMENTS, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },

  async [DocumentActionType.FETCH_CREDIT_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getCreditDocuments(dto)
      commit(DocumentMutationType.SET_CREDIT_DOCUMENTS, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },

  async [DocumentActionType.FETCH_INVOICE_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getInvoiceDocuments(dto)
      commit(DocumentMutationType.SET_INVOICE_DOCUMENTS, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },

  async [DocumentActionType.FETCH_OTHER_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getOtherDocuments(dto)
      commit(DocumentMutationType.SET_OTHER_DOCUMENTS, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },

  async [DocumentActionType.FETCH_PROFORMAS_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getProformasDocuments(dto)
      commit(DocumentMutationType.SET_PROFORMAS_DOCUMENTS, response)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
