import gql from 'graphql-tag'

export const fetchProductFromWishlistMutation = gql`
mutation ($wishlist_id: ID!, $wishlist_item_id: ID!) {
  removeProductsFromWishlist(
  wishlistId: $wishlist_id
  wishlistItemsIds: [$wishlist_item_id]){
    wishlist {
      id
      items_count
      items_v2 (currentPage: 1, pageSize: 8 ) {
        items {
          id
          quantity
          ... on BundleWishlistItem {
            bundle_options {
              values {
                id
                label
                quantity
              }
            }
          }
          product {
            uid
            name
            sku
            price_range {
              minimum_price {
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                regular_price {
                  currency
                  value
                }
              }
            }
          }
        }
      }
    }
    user_errors {
      code
      message
    }
  }
}`
