
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfirmModal',

  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    cancel: {
      type: String,
      default: ''
    },
    confirm: {
      type: String,
      default: 'OK'
    },
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  emits: ['confirm', 'cancel'],

  methods: {
    onConfirm () {
      this.$emit('confirm')
      this.closeModal()
    },
    onCancel () {
      this.$emit('cancel')
      this.closeModal()
    }
  }
})
