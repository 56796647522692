export enum OrderActionType{
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_SAVED_ORDERS = 'FETCH_SAVED_ORDERS',
  FETCH_SAVED_ORDER_DETAILS = 'FETCH_SAVED_ORDER_DETAILS',
  REORDER_SAVED_ORDER = 'REORDER_SAVED_ORDER',
  DELETE_SAVED_ORDER = 'DELETE_SAVED_ORDER',
  REORDER_HISTORY_ORDER = 'REORDER_HISTORY_ORDER',
  CANCEL_HISTORY_ORDER = 'CANCEL_HISTORY_ORDER',
  FETCH_SHIP_TO_FILTER = 'FETCH_SHIP_TO_FILTER',
  UPLOAD_BULK_ORDER = 'UPLOAD_BULK_ORDER',
  FETCH_ORDER_TRACKING = 'FETCH_ORDER_TRACKING'

}
