export enum CatalogMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_FAVORITES_LOADING = 'SET_FAVORITES_LOADING',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCTS_INFO = 'SET_PRODUCTS_INFO',
  RESET_PRODUCTS_PAGE = 'RESET_PRODUCTS_PAGE',
  SET_FILTERS = 'SET_FILTERS',
  SET_FAVORITES = 'SET_FAVORITES',
  SET_PRODUCT = 'SET_PRODUCT',
  SET_PACKAGING = 'SET_PACKAGING',
  SET_WISHLIST = 'SET_WISHLIST',
  SET_ALIGNMENT = 'SET_ALIGNMENT',
  SET_QUERIES = 'SET_QUERIES',
  SET_ERROR = 'SET_ERROR',
  SET_SHIP_TO = 'SET_SHIP_TO',
  SET_PRODUCT_WISHLIST = 'SET_PRODUCT_WISHLIST',
  SET_PRODUCT_WISHLIST_INFO = 'SET_PRODUCT_WISHLIST_INFO',
  SET_TRANSPORT_TYPES = 'SET_TRANSPORT_TYPES'
}
