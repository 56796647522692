
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MessageModal',
  props: {
    message: {
      type: String,
      required: true
    },
    subMessage: {
      type: String,
      default: '',
      required: false
    },
    buttonText: {
      type: String,
      required: false,
      default: 'OK'
    },
    isErrorModal: {
      type: Boolean,
      required: false,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },

  emits: ['close'],

  methods: {
    close () {
      this.$emit('close')
    }
  }
})
