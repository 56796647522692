export enum UserActionType {
  FETCH_USERS = 'FETCH_USERS',
  FETCH_SELECTED_USER = 'FETCH_SELECTED_USER',
  SAVE_USER = 'SAVE_USER',
  UPDATE_USER = 'UPDATE_USER',
  FETCH_PAYER_DETAILS = 'FETCH_PAYER_DETAILS',
  FETCH_PAYER_DETAILS_IT = 'FETCH_PAYER_DETAILS_IT',
  FETCH_PAYER_SOLD_TO = 'FETCH_PAYER_SOLD_TO',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  SET_USER_STATUS = 'SET_USER_STATUS'
}
