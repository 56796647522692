import { IProduct } from '@/store/modules/catalog/interfaces/products/IProduct'

export const updateObjectInArray = (items: IProduct[], itemId: string, objPropName: string, newObjProps: any) => {
  return items.map((u: any) => {
    if (u[objPropName] === itemId) {
      return { ...u, ...newObjProps }
    }
    return u
  })
}
