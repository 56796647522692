import axios from 'axios'
import { constants } from '@/constants'
const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    timeout: 55000
  })

  instance.interceptors.request.use(
    (configParam) => {
      const idToken = sessionStorage.getItem('id_token')
      configParam.headers.Authorization = idToken

      const Language = localStorage.getItem('lang')
      if (Language) {
        configParam.params = { Language, ...configParam.params }
      } else {
        const languageValue = 'en'
        localStorage.setItem('lang', languageValue)
        configParam.params = { languageValue, ...configParam.params }
      }
      return configParam
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return instance
}
// Creating instances for services to be called, for now the suffixes are hardcoded.

// QA env

export const cdcServiceAPI = createAxiosInstance(constants.api.cdcService)
export const magnoliaServiceAPI = createAxiosInstance(constants.api.magnoliaService)
export const generalServiceAPI = createAxiosInstance(constants.api.generalservice)
// TODO change the API suffixes for some variables to support more countries (enviroments) in API management
