export enum CatalogActionType {
  FETCH_CATEGORIES = 'FETCH_CATEGORIES',
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  FETCH_FAVORITES = 'FETCH_FAVORITES',
  FETCH_PRODUCT = 'FETCH_PRODUCT',
  FETCH_WISHLIST = 'FETCH_WISHLIST',
  FETCH_ADD_TO_WISHLIST = 'FETCH_ADD_TO_WISHLIST',
  FETCH_REMOVE_FROM_WISHLIST = 'FETCH_REMOVE_FROM_WISHLIST',
  FETCH_ALIGNMENT = 'FETCH_ALIGNMENT',
  FETCH_ADD_TO_CART = 'FETCH_ADD_TO_CART',
  FETCH_SHIP_TO = 'FETCH_SHIP_TO',
  FETCH_PRODUCTS_AGGREGATIONS = 'FETCH_PRODUCTS_AGGREGATIONS',
  FETCH_PRODUCT_WISHLIST = 'FETCH_PRODUCT_WISHLIST',
  FETCH_TRANSPORT_TYPES = 'FETCH_TRANSPORT_TYPES'
}
