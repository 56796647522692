import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { ITrainingLearningMutations } from '@/store/modules/trainingLearning/interfaces/ITrainingLearningMutation'
import { ITrainingLearningState } from '@/store/modules/trainingLearning/interfaces/ITrainingLearningState'
import { TrainingLearningActionType } from '@/store/modules/trainingLearning/interfaces/action-type'
import { TrainingLearningMutationType } from '@/store/modules/trainingLearning/interfaces/mutation-type'
import { ITrainingLearningActions } from '@/store/modules/trainingLearning/interfaces/ITrainingLearningAction'
import {
  IFetchTrainingLearningResponse
} from '@/store/modules/trainingLearning/interfaces/trainingLearning/api/fetchTrainingLearning/IFetchTrainingLearningResponse'
import { trainingLearningAPI } from '@/store/modules/trainingLearning/api/getTrainingLearning'
import {
  IFetchTrainingLearningRequest
} from '@/store/modules/trainingLearning/interfaces/trainingLearning/api/fetchTrainingLearning/IFetchTrainingLearningRequest'
import {
  IFetchSelectedTrainingLearningRequest
} from '@/store/modules/trainingLearning/interfaces/selectedTrainingLearning/api/fetchSelectedTrainingLearning/IFetchSelectedTrainingLearningRequest'
import { ITrainingLearning } from '@/store/modules/trainingLearning/interfaces/ITrainingLearning'

const state = (): ITrainingLearningState => ({
  trainingLearning: {
    total: 0,
    offset: 0,
    limit: 0,
    results: []
  },
  selectedTrainingLearning: null,
  search: '',
  loading: false
})

const mutations: MutationTree<ITrainingLearningState> & ITrainingLearningMutations = {
  [TrainingLearningMutationType.SET_LOADING] (state: ITrainingLearningState, payload: boolean) {
    state.loading = payload
  },
  async [TrainingLearningMutationType.SET_TRAINING_LEARNING] (state: ITrainingLearningState, payload: IFetchTrainingLearningResponse) {
    state.trainingLearning = payload
  },
  async [TrainingLearningMutationType.SET_SELECTED_TRAINING_LEARNING] (state: ITrainingLearningState, payload: ITrainingLearning) {
    state.selectedTrainingLearning = payload
  },
  async [TrainingLearningMutationType.SET_SEARCH_TRAINING] (state: ITrainingLearningState, payload: string) {
    state.search = payload
  }
}

const actions: ActionTree<ITrainingLearningState, IRootState> & ITrainingLearningActions = {
  async [TrainingLearningActionType.FETCH_TRAINING_LEARNING] ({ commit }, dto: IFetchTrainingLearningRequest) {
    commit(TrainingLearningMutationType.SET_LOADING, true)
    const data = await trainingLearningAPI.fetchTrainingLearning(dto)
    commit(TrainingLearningMutationType.SET_TRAINING_LEARNING, data)
    commit(TrainingLearningMutationType.SET_LOADING, false)
  },
  async [TrainingLearningActionType.FETCH_SELECTED_TRAINING_LEARNING] ({ commit }, dto: IFetchSelectedTrainingLearningRequest) {
    commit(TrainingLearningMutationType.SET_LOADING, true)
    const data = await trainingLearningAPI.fetchSelectedTrainingLearning(dto)
    commit(TrainingLearningMutationType.SET_SELECTED_TRAINING_LEARNING, data)
    commit(TrainingLearningMutationType.SET_LOADING, false)
  }
}

const TrainingLearningModule = {
  state,
  mutations,
  actions
}

export default TrainingLearningModule
