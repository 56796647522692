import gql from 'graphql-tag'

export const fetchWishlistQuery = gql(`
 {
 customer {
    wishlists {
      id
      items_count
      items_v2 {
        items {
          id
          quantity
          product {
            uid
            sku
          }
        }
      }
    }
    }
  }
`)
