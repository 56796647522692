import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a329ebe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "help-action-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asahi_icon = _resolveComponent("asahi-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/support/faq",
    class: "link"
  }, {
    default: _withCtx(() => [
      (_ctx.isIncludeLogin && _ctx.allPermission?.includes('Support'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_asahi_icon, { icon: "question-circle" }),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('Do you need help?')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}