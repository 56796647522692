
import { defineComponent } from 'vue'

const afterShowModal = () => {
  const body = document.body
  const scrollY = window.scrollY
  body.style.position = 'fixed'
  body.style.top = `-${scrollY}px`
  body.style.left = '0'
  body.style.right = '0'
}

const afterCloseModal = () => {
  const body = document.body
  const scrollY = body.style.top
  body.style.position = ''
  body.style.top = ''
  window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

export default defineComponent({
  name: 'ModalComponent',

  props: {
    showCloseButton: {
      type: Boolean,
      default: true
    },
    backdropDismiss: {
      type: Boolean,
      default: true
    },
    overFlowScroller: {
      type: Boolean,
      default: false
    }
  },

  emits: ['onClose'],

  data () {
    return {
      showModal: false
    }
  },

  methods: {
    toggle () {
      if (this.showModal === true) this.close()
      else this.open()
    },

    open () {
      this.showModal = true
      this.$nextTick(() => {
        afterShowModal()
      })
    },

    close () {
      this.showModal = false
      this.$nextTick(() => {
        afterCloseModal()
        this.$emit('onClose')
      })
    }
  }
})
