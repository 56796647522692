import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "page__content" }
const _hoisted_3 = {
  key: 0,
  class: "page__header"
}
const _hoisted_4 = { class: "d-flex justify-content-end" }
const _hoisted_5 = {
  href: "/login",
  class: "d-flex align-items-center asahi-link text-decoration-none px-4"
}
const _hoisted_6 = {
  key: 1,
  class: "hr mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asahi_logo = _resolveComponent("asahi-logo")!
  const _component_asahi_language = _resolveComponent("asahi-language")!
  const _component_asahi_typography = _resolveComponent("asahi-typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.name != 'login')
        ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
            _createVNode(_component_asahi_logo, {
              theme: _ctx.theme,
              role: "button",
              onClick: _cache[0] || (_cache[0] = () => _ctx.$navigate('/login'))
            }, null, 8, ["theme"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_asahi_language, {
                languages: _ctx.languages,
                activeLanguage: _ctx.activeLanguage,
                onLanguagechange: _cache[1] || (_cache[1] = (event) => _ctx.changeLanguage(event?.detail))
              }, null, 8, ["languages", "activeLanguage"]),
              _createElementVNode("a", _hoisted_5, [
                _createVNode(_component_asahi_typography, { color: "primary-black" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Login')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.$route.name != 'login')
        ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}