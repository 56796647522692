import gql from 'graphql-tag'

export const validateProductPrice = gql(`
query ($productItems: [ProductItemInput]!) {
  validateProductPrice(
    productItems: $productItems
  ) {
    message
  }
}
`)
