import gql from 'graphql-tag'

export const fetchProductWishlistQuery = gql`
  {
    products(filter: {}, pageSize: 4, currentPage: 1, sort: {}) {
      total_count
      items {
        name
        sku
        product_name_sap
        __typename
        description {
          html
        }
        ean
        ean_ea
        your_price
        your_price_tax
        base_price
        base_price_tax
        downloads
        favourites
        promo
        weight
        gross_weight_for_one_cas
        pallet_weight
        packaging
        options_container
        beer_type
        number_of_layers_per_pallet
        product_name_sap
        sku_product_detail_page
        brand
        alcohol_content
        hectolitres_for_one_cas_frontend
        hectolitres_per_pallet
        number_of_items_in_box
        number_of_items_on_pallet
        number_of_products_on_layer
        other_info_shelf_life
        pack_format
        pack_material
        sap_pack
        sap_pack_configuration
        returnable_package
        sub_brand
        volume
        country_of_origin
        catalog_product_name
        filter_packaging
        product_last
        product_new
        product_sale
        canonical_url
        url_path
        url_key
        url_rewrites {
          url
          parameters {
            name
            value
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        swatch_image
        small_image {
          url
        }
        cart_fields {
          packaging_cart
          layer_cart
          pallets_cart
        }
        thumbnail {
          url
        }
        image {
          url
        }
        media_gallery {
          url
        }
        related_products {
          uid
          name
          sku
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
            maximum_price {
              final_price {
                value
                currency
              }
            }
          }
          swatch_image
          small_image {
            url
          }
          thumbnail {
            url
          }
          image {
            url
          }
          media_gallery {
            url
          }
        }
        ... on BundleProduct {
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum
            options {
              id
              label
              quantity
              can_change_quantity
              price
              price_type
              is_default
              position
              product {
                id
                name
                sku
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                  }
                }
                your_price
                #   stock_status
                #   __typename
              }
            }
          }
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`
