export const constants = {
  menuWithoutLogin: process.env.VUE_APP_MENU_WITHOUT_LOGIN?.split(','),
  // TODO: get rid of the hardcoded values
  saml: {
    eLearningURL: process.env.VUE_APP_CDC_ELEARNING,
    pubMenuURL: process.env.VUE_APP_CDC_PUBMENU,
    empties: process.env.VUE_APP_EMPTIES_REDIRECT_URL
  },
  application: {
    theme: process.env.VUE_APP_THEME,
    contactUsEmail: process.env.VUE_APP_CONTACT_US_MAIL,
    contactUsEmailSK: process.env.VUE_APP_CONTACT_US_MAIL_SK,
    contactUs: process.env.VUE_APP_CONTACT_US,
    contactUsSK: process.env.VUE_APP_CONTACT_US_SK,
    defaulLang: 'en',
    pageTitle: 'Home page',
    powerBiSchema: process.env.VUE_APP_ABOUT_POWER_BI_SCHEMA
  },
  gigyaConfig: {
    screenSet: process.env.VUE_APP_SCREENSET,
    startScreen: process.env.VUE_APP_STARTSCREEN,
    forgotScreen: process.env.VUE_APP_FORGOTSCREEN,
    resetScreen: process.env.VUE_APP_RESETSCREEN
  },
  gtm: {
    containerId: process.env.VUE_APP_GTM_CONTAINER_ID
  },
  api: {
    cdcService: process.env.VUE_APP_CDC_SERVICE,
    magnoliaService: process.env.VUE_APP_MAGNOLIA_SERVICE,
    generalservice: process.env.VUE_APP_GENERAL_SERVICE
  }
} as const
