import { magnoliaServiceAPI } from '@/store/api-instance'
import { AxiosResponse } from 'axios'
import { IFetchFilesRequest } from '@/store/modules/files/interfaces/files/api/fetchFiles/IFetchFilesRequest'
import { IFetchFilesResponse } from '@/store/modules/files/interfaces/files/api/fetchFiles/IFetchFilesResponse'
import { IBulkFilesRequest } from '@/store/modules/files/interfaces/files/api/bulkFiles/IBulkFilesRequest'
import { IFetchMarketingMaterialsRequest } from '@/store/modules/files/interfaces/files/api/marketingMaterials/IFetchMarketingMaterialsRequest'
import { IFetchMarketingMaterialsResponse } from '@/store/modules/files/interfaces/files/api/marketingMaterials/IFetchMarketingMaterialsResponse'

export const filesAPI = {
  async fetchFiles (dto: IFetchFilesRequest): Promise<IFetchFilesResponse> {
    const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchFilesResponse>('downloadmaterial', { params: dto })
    return response.data
  },
  async fetchBrandList (): Promise<IFetchFilesResponse> {
    const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchFilesResponse>('downloadmaterial/GetAllBrandsAsync')
    return response.data
  },
  async fetchBulkFiles (dto: IBulkFilesRequest): Promise<Blob> {
    const response: AxiosResponse = await magnoliaServiceAPI.post<Blob>('downloadmaterial/bulk-files', dto, { responseType: 'blob' })
    return response.data
  },
  async fetchMarketingMaterials (dto: IFetchMarketingMaterialsRequest): Promise<IFetchMarketingMaterialsResponse> {
    const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchMarketingMaterialsResponse>('downloadmaterial/GetAllDMFolders', { params: dto })
    return response.data
  }
}
