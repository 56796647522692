import { magnoliaServiceAPI } from '@/store/api-instance'
import {
  IFetchTrainingLearningRequest
} from '@/store/modules/trainingLearning/interfaces/trainingLearning/api/fetchTrainingLearning/IFetchTrainingLearningRequest'
import {
  IFetchSelectedTrainingLearningRequest
} from '@/store/modules/trainingLearning/interfaces/selectedTrainingLearning/api/fetchSelectedTrainingLearning/IFetchSelectedTrainingLearningRequest'
import {
  IFetchTrainingLearningResponse
} from '@/store/modules/trainingLearning/interfaces/trainingLearning/api/fetchTrainingLearning/IFetchTrainingLearningResponse'

import { ITrainingLearning } from '@/store/modules/trainingLearning/interfaces/ITrainingLearning'
import { AxiosResponse } from 'axios'

export const trainingLearningAPI = {
  async fetchTrainingLearning (dto: IFetchTrainingLearningRequest): Promise<IFetchTrainingLearningResponse> {
    const trainingLearningApi = 'trainingLearning'

    const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchTrainingLearningResponse>(trainingLearningApi, { params: dto })
    return response.data
  },
  async fetchSelectedTrainingLearning (dto: IFetchSelectedTrainingLearningRequest): Promise<ITrainingLearning> {
    const selectedtrainingLearningApi = 'trainingLearning'
    const response: AxiosResponse = await magnoliaServiceAPI.get<ITrainingLearning>(`${selectedtrainingLearningApi}${dto.path}`)
    return response.data
  }
}
