export enum DocumentActionType {
  FETCH_DOCUMENTS = 'FETCH_DOCUMENTS',
  FETCH_TESI_DOCUMENTS = 'FETCH_TESI_DOCUMENTS',
  FETCH_CABLOG_DOCUMENTS = 'FETCH_CABLOG_DOCUMENTS',
  FETCH_INVOICE_DOCUMENTS = 'FETCH_INVOICE_DOCUMENTS',
  FETCH_CREDIT_DOCUMENTS = 'FETCH_CREDIT_DOCUMENTS',
  FETCH_SHIPPING_DOCUMENTS = 'FETCH_SHIPPING_DOCUMENTS',
  FETCH_OTHER_DOCUMENTS = 'FETCH_OTHER_DOCUMENTS',
  FETCH_PROFORMAS_DOCUMENTS = 'FETCH_PROFORMAS_DOCUMENTS'
}
