
import { defineComponent } from 'vue'
import { setI18nLanguage } from '@/i18n'
import { LanguagesType } from '@/types'
import { constants } from '@/constants'

export default defineComponent({
  name: 'BeforeLoginLayout',

  data: () => ({
    activeLanguage: (localStorage.getItem('lang') || ('en')) as LanguagesType,
    theme: constants.application.theme
  }),

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    languages () {
      let locale = 'en'
      let languageList = ['en', 'es']
      if (this.user.country === 'SVK') {
        locale = 'sk'
        languageList = ['sk', 'en']
      }

      localStorage.setItem('lang', locale)
      return languageList
    }
  },

  methods: {
    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    }
  }
})
