import { AxiosResponse } from 'axios'
import { generalServiceAPI } from '@/store/api-instance'
import { shipmentPlaces, orderTransportTypes } from '@/utils/mockAPIresponse'
import { IOrder, IOrderListRequest, IUploadBulkOrder, IOrderTracking, IOrderTrackingRequest } from '../interfaces/orders.interface'

async function getOrdersAPI (dto: IOrderListRequest) {
  const response: AxiosResponse = await generalServiceAPI.get<IOrder>('orders', { params: dto })
  return response.data
}

async function getShipmentPlacesAPI () {
  return await JSON.parse(shipmentPlaces)
}

async function getOrderTransportTypesAPI () {
  return await JSON.parse(orderTransportTypes)
}

async function uploadBulkOrderAPI (dto: IUploadBulkOrder) {
  const response: AxiosResponse = await generalServiceAPI.post<IOrder>(`orders/BulkUploadOrdersAsync?store=${dto.store}`, dto.fileAttachment, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

async function getOrderTrackingAPI (dto: IOrderTrackingRequest) {
  const response: AxiosResponse = await generalServiceAPI.get<IOrderTracking>(`orders/GetTracking/${dto?.orderId}`)
  return response.data
}

export { getOrdersAPI, getShipmentPlacesAPI, getOrderTransportTypesAPI, uploadBulkOrderAPI, getOrderTrackingAPI }
