import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tutorial-modal" }
const _hoisted_2 = { class: "carousel__item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "d-flex justify-content-between mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_asahi_button = _resolveComponent("asahi-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel, {
      ref: "tutorialCarousel",
      onSlideStart: _ctx.handleSlideStart,
      onInit: _ctx.handleInit
    }, {
      addons: _withCtx(() => [
        _createVNode(_component_Pagination)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedHelpPages, (helpPage, index) => {
          return (_openBlock(), _createBlock(_component_Slide, { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", null, _toDisplayString(helpPage.title), 1),
                (helpPage.file.link)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: helpPage.file.link,
                      alt: ""
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  innerHTML: helpPage.body
                }, null, 8, _hoisted_4)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["onSlideStart", "onInit"]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, [
        (_ctx.currentSlide > 0)
          ? (_openBlock(), _createBlock(_component_asahi_button, {
              key: 0,
              class: "back-button",
              color: "primary-favourite",
              onClick: _ctx.back
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Back')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        (_ctx.currentSlide === _ctx.sortedHelpPages.length - 1)
          ? (_openBlock(), _createBlock(_component_asahi_button, {
              key: 0,
              color: "primary",
              onClick: _ctx.done
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Done')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : (_openBlock(), _createBlock(_component_asahi_button, {
              key: 1,
              color: "primary",
              onClick: _ctx.next
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Next')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
      ])
    ])
  ]))
}