import { magnoliaServiceAPI } from '@/store/api-instance'
import {
  IFetchArticlesRequest
} from '@/store/modules/articles/interfaces/articles/api/fetchArticles/IFetchArticlesRequest'
import {
  IFetchArticlesResponse
} from '@/store/modules/articles/interfaces/articles/api/fetchArticles/IFetchArticlesResponse'
import {
  IFetchSelectedArticleRequest
} from '@/store/modules/articles/interfaces/selectedArticle/api/fetchSelectedArticle/IFetchSelectedArticleRequest'

import { IArticle } from '@/store/modules/articles/interfaces/IArticle'
import { AxiosResponse } from 'axios'

export const articlesAPI = {
  async fetchArticles (dto: IFetchArticlesRequest): Promise<IFetchArticlesResponse> {
    const { isLoggedIn } = dto
    const articlesApi = isLoggedIn ? 'articles' : 'publicarticles'
    const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchArticlesResponse>(articlesApi, { params: dto })
    return response.data
  },
  async fetchSelectedArticle (dto: IFetchSelectedArticleRequest): Promise<IArticle> {
    const { isLoggedIn } = dto
    const selectedArticlesApi = isLoggedIn ? 'articles' : 'publicarticles'
    const response: AxiosResponse = await magnoliaServiceAPI.get<IArticle>(`${selectedArticlesApi}${dto.path}`)
    return response.data
  }
}
