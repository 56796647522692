import { AxiosResponse } from 'axios'
import { magnoliaServiceAPI } from '@/store/api-instance'
import { ISupportRequest } from '@/store/modules/support/interfaces/api/ISupportRequest'
import {
  ISupportItem,
  ISupportResponse,
  ISupportPageResponse,
  IKnowledgeBaseItem,
  IKnowledgeBaseResponse
} from '@/store/modules/support/interfaces/api/ISupportResponse'

export const supportAPI = {
  async getFaqItems (dto: ISupportRequest): Promise<ISupportResponse> {
    const response: AxiosResponse<ISupportResponse> = await magnoliaServiceAPI.get('faqitems', { params: dto })
    return response.data
  },

  async getAllFaqItems (dto: ISupportRequest, oldData?: Array<ISupportItem>): Promise<Array<ISupportItem>> {
    const { results, total, limit } = await this.getFaqItems(dto)

    oldData = (oldData || []).concat(results)

    if (total > oldData.length) {
      return await this.getAllFaqItems({ ...dto, offset: oldData.length, limit }, oldData)
    }

    return oldData
  },

  async getFaqPages (dto: ISupportRequest): Promise<ISupportPageResponse> {
    const response: AxiosResponse<ISupportPageResponse> = await magnoliaServiceAPI.get('faqpages', { params: dto })
    return response.data
  },

  async getFaqKnowledgeBaseItems (dto: ISupportRequest): Promise<IKnowledgeBaseResponse> {
    const response: AxiosResponse<IKnowledgeBaseResponse> = await magnoliaServiceAPI.get('knowledgebase', { params: dto })
    return response.data
  },

  async getAllFaqKnowledgeBaseItems (
    dto: ISupportRequest,
    oldData?: Array<IKnowledgeBaseItem>
  ): Promise<Array<IKnowledgeBaseItem>> {
    const { results, total, limit } = await this.getFaqKnowledgeBaseItems(dto)

    oldData = (oldData || []).concat(results)

    if (total > oldData.length) {
      return await this.getAllFaqKnowledgeBaseItems({ ...dto, offset: oldData.length, limit }, oldData)
    }

    return oldData
  }
}
